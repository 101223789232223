import React, { Component } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Popup from "reactjs-popup";
import CloseIcon from "@material-ui/icons/Close";
import { confirmAlert } from "react-confirm-alert"; // Import
import { UserService } from "../service/user.service";

export enum popupView {
  none = "none",
  deposit = "deposit",
  withdraw = "withdraw",
  point = "point",
  notice = "notice",
  note = "note",
  help = "help",
  user = "user",
  reg = "reg",
  login = "login",
}

interface Props {
  handleClose: () => any;
  tryLogin: (id: any, pw: any) => any;
  changePopup: (value: any) => any;
}

interface State {
  ID: string;
  PW: string;
}

export class Login extends Component<Props, State> {
  userService = new UserService();
  constructor(props: Props) {
    super(props);

    this.state = {
      ID: "",
      PW: "",
    };
  }

  render() {
    return (
      <Popup
        // key={`main_popup_note_${pop.idx}`}
        closeOnDocumentClick={false}
        open={true}
        contentStyle={{
          zIndex: 99,
          background: "none",
          border: "none",
          width: "none",
        }}
        overlayStyle={{
          overflow: "scroll",
        }}
      >
          <div>
            <div
              className="modal fade show"
              id="board_notice-modal"
              aria-labelledby="board_notice-modalLabel"
              aria-modal="true"
              role="dialog"
              style={{ display: "block" }}
            >
              <div className="modal-dialog modal-xl">
                <div className="modal-content">
                  <div className="modal-header">
                    <div className="modal-title-wrapper">
                      <div className="modal-title-container">
                        <h5 className="title-text">로그인</h5>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        this.props.handleClose();
                      }}
                    >
                      <span aria-hidden="true">
                        <img src="images/x-button.png" />
                      </span>
                    </button>
                  </div>
                  <div
                    className="modal-body"
                    style={{ width: "100%", height: "500px", overflow: "auto" }}
                  >
                    {/* Content Start */}

                    <div className="signup container-md">
                      <form>
                        <div className="signup-frm">
                          <div className="row my-1">
                            <div className="col-sm-3 title">
                              <label className="">아이디</label>
                            </div>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                name="id"
                                className="frm_input d-inline-flex"
                                minLength={3}
                                maxLength={15}
                                style={{ marginRight: "5px" }}
                                onChange={({ target: { value } }) =>
                                  this.setState({ ID: value })
                                }
                              />
                            </div>
                          </div>
                          <div className="row my-1">
                            <div className="col-sm-3 title">
                              <label className="">비밀번호</label>
                            </div>
                            <div className="col-sm-6 ">
                              <input
                                type="password"
                                name="pass"
                                className="frm_input"
                                maxLength={15}
                                onChange={({ target: { value } }) =>
                                  this.setState({ PW: value })
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>

                    <div className="btm_btn_align">
                      <button
                        className="btn_iframe btn_cyan"
                        style={{ minWidth: "70px", marginRight: "5px" }}
                        onClick={() => {
                          if (
                            this.state.ID == null ||
                            this.state.ID == "" ||
                            this.state.PW == "" ||
                            this.state.PW == null
                          ) {
                            return;
                          }
                          this.props.tryLogin(this.state.ID, this.state.PW);
                        }}
                      >
                        로그인
                      </button>
                      <button
                        className="btn_iframe btn_cyan"
                        style={{ minWidth: "70px" }}
                        onClick={() => {
                          this.props.changePopup(popupView.reg);
                        }}
                      >
                        회원가입
                      </button>
                    </div>

                    {/* Content End */}
                  </div>
                </div>
              </div>
            </div>
          </div>
      </Popup>
    );
  }
}
