import React, { Component } from "react";
import { HashRouter as Router, Route, Routes } from "react-router-dom";
import { isBrowser, isMobile } from "react-device-detect";
import { UserService } from "../service/user.service";
import { Main } from "./main";
import SoundPlays from "../SoundPlay";
import { NotePopup } from "./notepopup";

interface Props {
  authenticated: boolean;
  session: string;
  tryLogin: (id: any, pw: any) => any;
  tryLoginOut: () => any;

  SetCookie: (name: string, data: any) => any;
  GetCookie: (name: string) => any;
}

interface State {
  user: {
    id: string;
    
    rolling: number;
    in_balance: number;
    balance: number;
    point: number;
    bankname: string;
    banknum: string;
    bankowner: string;
  };
  helpCount: number;
  noteCount: number;
}

async function sleep(ms: any) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export class Web extends Component<Props, State> {
  userService = new UserService();
  constructor(props: Props) {
    super(props);
    this.state = {
      user: {
        id: "",
        in_balance: 0,
        balance: 0,
        rolling: 0,
        point: 0,
        
        bankname: "",
        banknum: "",
        bankowner: "",
      },
      helpCount: 0,
      noteCount: 0,
      
    };

    if (isMobile) {
      setImmediate(async () => {
        try {
          for (;;) {
            this.updateUser();

            await sleep(30000);
          }
        } catch (ex) {}
      });
      // this.updateUser();
    }
  }

  componentDidMount() {}

  updateUser = () => {
    this.userService.healthCheck().then((s: any) => {
      if (s.status === "success") {
        let user = {
          id: s.user.id,
          in_balance: s.user.in_balance,
          balance: s.user.balance,
          point: s.user.point,
          bankname: s.user.bankname,
          banknum: s.user.banknum,
          bankowner: s.user.bankowner,
          rolling: s.user.rolling,

        };

        if (s.helpCount > 0) {
          SoundPlays("helpCount");
        }
        if (s.noteCount > 0) {
          SoundPlays("helpCount");
        }

        if (JSON.stringify(user) !== JSON.stringify(this.state.user)) {
          this.setState({ user: user });
        }

        if (this.state.helpCount !== s.helpCount) {
          this.setState({ helpCount: s.helpCount });
        }
       
        if (this.state.noteCount !== s.noteCount) {
          this.setState({  noteCount: s.noteCount });
        }
      } else {
        window.location.hash = "/";
      }
    });
  };

  render() {
    return (
      <Router>
        <Routes>
          <Route  path="/" element={
                       <Main
                       activeHelp={this.state.helpCount > 0}
                       activeNote={this.state.noteCount > 0}
                       
                       authenticated={this.props.authenticated}
                       session={this.props.session}
                       user={this.state.user}
                       tryLogin={this.props.tryLogin}
                       tryLoginOut={() => {
                         this.setState({
                           user: {
                             id: "",
                             
                             in_balance: 0,
                             rolling: 0,
                             balance: 0,
                             point: 0,
                             bankname: "",
                             banknum: "",
                             bankowner: "",
                           },
                         });
                         this.props.tryLoginOut();
                       }}
                       updateUser={this.updateUser}
                     />
          }>
 
          </Route>
          </Routes>

        {this.props.authenticated && (
          <NotePopup
            SetCookie={this.props.SetCookie}
            GetCookie={this.props.GetCookie}
          ></NotePopup>
        )}

        <div id="helpCount" style={{ display: "none" }}>
          0
        </div>

      </Router>
    );
  }
}
